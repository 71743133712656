<template>
  <div class="customer">
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      size="small"
      label-width="auto"
    >
      <el-form-item label="创建日期">
        <el-date-picker
          v-model="formInline.date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="自定义标签">
        <el-select
          v-model="formInline.tag"
          multiple
          :multiple-limit="5"
          placeholder="请选择"
          style="width: 400px"
        >
          <el-option
            v-for="item in formInline.options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入联系人姓名/电话/主体名称"
          v-model="formInline.userInfo"
          class="input-with-select"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      fit
      stripe
      @selection-change="handleSelectionChange"
      :show-overflow-tooltip="true"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column align="center" label="头像" prop="headporrait">
        <template v-slot="scope">
          <img
            v-show="scope.row.headporrait"
            :src="scope.row.headporrait"
            alt=""
            width="40"
            height="40"
            style="border-radius: 50%"
          />
          <span v-show="!scope.row.headporrait">暂无图片</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="联系人姓名" prop="manname"></el-table-column>
      <el-table-column align="center" label="联系人电话" prop="mobile"></el-table-column>
      <el-table-column align="center" label="主体名称" prop="entityname"></el-table-column>
      <el-table-column align="center" label="自定义标签" prop="labels">
        <template v-slot="scope">
          <span v-show="scope.row.labels" v-for="(item,index) in scope.row.labels.slice(0,5)" :key="index">
              <span v-if="index < scope.row.labels.slice(0,5).length-1">{{item.name}}、</span>
              <span v-if="index == scope.row.labels.slice(0,5).length-1">{{item.name}}</span>
          </span>
          <span v-show="!scope.row.labels">暂无自定义标签</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remaks"></el-table-column>
      <el-table-column align="center" label="加入日期" prop="createtime"></el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="300">
        <template v-slot="scope">
          <el-button size="mini" type="primary" @click="handleDetails(scope.row)">详情</el-button>
          <el-button size="mini" @click="handleMatch(scope.row)" type="success"
            >匹配标签</el-button
          >
          <el-button size="mini" type="warning" @click="handleAdvice(scope.row)"
            >生成建议表</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <div>
        <el-button
          type="danger"
          v-show="tableData.length > 0"
          @click="handleDelete"
          >删除</el-button
        >
      </div>
      <div>&nbsp;</div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../network';
export default {
  data () {
    return {
      formInline: {
        date: '',
        region: '',
        regionOptions: [
          { value: '', label: '全部' },
          { value: 0, label: '待跟进' },
          { value: 1, label: '跟进中' },
          { value: 2, label: '无意向' }
        ],
        userInfo: '',
        options: [],
        tag: ''
      },
      tableData: [],
      pageSizes: [ 10, 20, 30, 40 ],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      visible: false
    };
  },
  mounted () {
    if (this.formInline.date.length > 0) {
      this.getAllorgCustomer(
        this.currentPage,
        this.pageSize,
        this.formInline.date[0],
        this.formInline.date[1],
        '',
        this.formInline.userInfo
      );
    } else {
      this.getAllorgCustomer(
        this.currentPage,
        this.pageSize,
        '',
        '',
        '',
        this.formInline.userInfo
      );
    }
    this.getAllCustomTags();
  },
  watch: {},
  computed: {
    parameters () {
      const a = '|';
      let b = '';
      this.formInline.tag.forEach((v, i) => {
        if (i === this.formInline.tag.length - 1) {
          b += v;
        } else {
          b += v + ',';
        }
      });
      if (this.formInline.date) {
        return this.formInline.date[0] + a + this.formInline.date[1] + a + a + b + a + this.formInline.userInfo;
      } else {
        return '' + a + '' + a + b + a + this.formInline.userInfo;
      }
    }
  },
  methods: {
    onSubmit () {
      const str = this.parameters.split('|');
      const startDate = str[0] === 'undefined' ? '' : str[0];
      const endDate = str[0] === 'undefined' ? '' : str[1];
      this.getAllorgCustomer(
        this.currentPage,
        this.pageSize,
        startDate,
        endDate,
        str[2],
        str[3]
      );
    },
    handleSelectionChange (val) {
      this.multipleSelection = val;
    },
    getAllorgCustomer (
      pageNo,
      pageSize,
      startDate,
      endDate,
      labelName,
      screen
    ) {
      const that = this;
      request({
        method: 'POST',
        url: '/pcp/org/allorgCustomer',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          pageNo: pageNo,
          pageSize: pageSize,
          startDate: startDate,
          endDate: endDate,
          labelName: labelName,
          screen: screen
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            that.tableData = res.data.data.datalist;
            that.total = res.data.data.amount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllCustomTags () {
      request({
        method: 'POST',
        url: '/pcp/org/getcustomerlabelibraryl',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.formInline.options = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 跳转详情页面
    handleDetails (v) {
      // this.$store.commit('backstage/set_customer_id',v.customerId)
      //  this.$store.commit('backstage/set_submit_id',v.id)
      localStorage.setItem('label_id', v.customerId);
      localStorage.setItem('customer_id', v.id);
      this.$router.push({
        name: 'details'
      });
    },
    // 跳转匹配标签页面
    handleMatch (v) {
      // this.$store.commit('backstage/set_submit_id',v.id)
      localStorage.setItem('label_id', v.customerId);
      localStorage.setItem('customer_id', v.id);
      localStorage.setItem('entityname', v.entityname);
      this.$router.push({
        name: 'matchLabel'
      });
    },
    // 跳转生成建议表页面
    handleAdvice (v) {
      localStorage.setItem('label_id', v.customerId);
      localStorage.setItem('customer_id', v.id);
      this.$router.push({
        name: 'advice'
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      const str = this.parameters.split('|');
      const startDate = str[0] === 'undefined' ? '' : str[0];
      const endDate = str[0] === 'undefined' ? '' : str[1];
      this.getAllorgCustomer(
        this.currentPage,
        val,
        startDate,
        endDate,
        str[2],
        str[3],
        str[4]
      );
    },
    handleCurrentChange (val) {
      this.currentPage = val;
      const str = this.parameters.split('|');
      const startDate = str[0] === 'undefined' ? '' : str[0];
      const endDate = str[0] === 'undefined' ? '' : str[1];
      this.getAllorgCustomer(
        val,
        this.pageSize,
        startDate,
        endDate,
        str[2],
        str[3],
        str[4]
      );
    },
    delete (idList) {
      request({
        method: 'POST',
        url: '/pcp/org/deleteCustoms',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          idList: idList
        }
      })
        .then((res) => {
          this.$message.error('删除成功');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDelete () {
      const res = [];
      this.multipleSelection.forEach((v) => {
        res.push(v.id);
      });
      let a = '';
      res.forEach((v, i) => {
        if (i === res.length - 1) {
          a += v;
        } else {
          a += v + ',';
        }
      });
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(a);
          const str = this.parameters.split('|');
          const startDate = str[0] === 'undefined' ? '' : str[0];
          const endDate = str[0] === 'undefined' ? '' : str[1];
          this.getAllorgCustomer(
            this.currentPage,
            this.pageSize,
            startDate,
            endDate,
            str[2],
            str[3]
          );
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    }
  }
};
</script>

<style lang="less" scoped>
.customer {
  background-color: #fff;
  padding: 40px;
  .el-table {
    margin-top: 80px;
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    > div:nth-child(2) {
      width: 400px;
    }
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-select .el-input {
  width: 130px;
}
</style>
